/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import "../css/app.scss";

import "bootstrap";
import bsCustomFileInput from "bs-custom-file-input";
import "jquery-ui";
import "jquery-ui/ui/widgets/sortable";

// Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
import $ from "jquery";
const TOKEN_ = "Bearer " + window.localStorage.getItem("user-token");

if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
  console.debug = () => {};
  console.info = () => {};
  console.warn = () => {};
}

bsCustomFileInput.init();

function setMainSidebarMenu() {
  const bodyDOMClass = document.querySelector("body")?.classList;
  if (
    window.innerWidth < 1024 &&
    Object.values(bodyDOMClass).includes("ea-sidebar-width-normal")
  ) {
    bodyDOMClass.remove("ea-sidebar-width-normal");
    bodyDOMClass.add("ea-sidebar-width-compact");
  }
}

$(document).ready(function () {
  $('[data-toggle="popover"]').popover();

  window.addEventListener("resize", setMainSidebarMenu);
  setMainSidebarMenu();

  const image = $("img");
  image.attr(
    "onerror",
    "this.onerror=null; this.src='assets/chapters/default-image.svg'"
  );
  $(".sortable").sortable({
    start: function (event, ui) {
      $(ui.helper).css("width", `${$(ui.item).width()}px`);
    },
    stop: function (event, ui) {
      let posicion = 1;
      const orden = [];

      $(".sortable")
        .children()
        .each(function () {
          orden.push($(this).data("id"));
          $(this).find(".order").html(posicion);
          posicion++;
        });

      const orderUrl = $(".sortable").data("orderurl");

      let orderRequest = $.ajax({
        type: "POST",
        url: orderUrl,
        data: { orden: orden },
      });

      orderRequest.done(function (result) {});

      orderRequest.fail(function () {
        location.reload();
      });
    },
  });

  $("#changeLangues .dropdown-item").on("click", function (e) {
    e.preventDefault();
    var lang = $(this).data("lang");

    var data = {
      language: lang,
    };

    $.ajax({
      contentType: "application/json",
      url: "/admin/user/change-language-local",
      type: "POST",
      dataType: "json",
      data: JSON.stringify(data),
      headers: { Authorization: TOKEN_ },
    })
      .done(function (data) {
        console.log(data);
        location.reload();
      })
      .fail(function (data) {
        console.log(data);
      });
  });

  $("#changeLocaleCampus .dropdown-item").on("click", function (e) {
    console.log("changeLocaleCampus");

    e.preventDefault();
    var lang = $(this).data("lang");

    var data = {
      language: lang,
    };

    $.ajax({
      contentType: "application/json",
      url: "/admin/user/change-language-local-campus",
      type: "PUT",
      dataType: "json",
      data: JSON.stringify(data),
      headers: { Authorization: TOKEN_ },
    })
      .done(function (data) {
        location.reload();
      })
      .fail(function (data) {
        console.log(data);
      });
  });

    document.querySelectorAll('table.datagrid td').forEach(function(td) {
        if (td.scrollWidth > td.clientWidth) {
            td.setAttribute('title', td.textContent.trim());
        }
    });


});
